<template>
  <div>
    <div v-html="title" />
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col
            v-if="post"
            sm="6"
          >
            <b-form-group
              label=""
              label-for="post-auto"
            >
              <b-form-checkbox
                id="post-auto"
                v-model="post.auto"
                name="Расстановка"
              >
                {{ post.auto ? 'Автоматическая' : 'Ручная' }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="Вариант"
              label-for="post-postStyle"
            >
              <b-form-radio-group
                id="post-postStyle"
                v-model="post.postStyle"
                :options="postStyleOptions"
                name="Вариант"
                :disabled="!post.auto"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-start">
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Сохранить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref } from '@vue/composition-api'
                            
export default {
  components: {
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormGroup,
    BFormRadioGroup,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: false,
    },
  },
  setup(props, { root }) {

    const post = ref(null)
    const resetData = () => {}

    const postStyleOptions = [
      { text: 'Слева', value: '0'},
      { text: 'Справа', value: '1'},
      { text: 'В короткую "Ногу"', value: '2'},
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data
          post.value = {
            auto: data.post.auto == 1 ? true : false,
            postStyle: data.post.postStyle,
          }
        })
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const formData = {
        address: JSON.stringify({
          auto: post.value.auto ? 1 : 0,
          postStyle: parseInt(post.value.postStyle, 10),
        }),
      }
      
      if (props.sendApi) {
        if (props.method === 'post') {
          axios.post(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(props.sendApi, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      loading,
      post,
      postStyleOptions,
    }
  },
}
</script>
